<template>
  <WeInput
    class="mb-0"
    v-bind:input="value"
    v-bind:id="componentId + '-value'"
    v-bind:small-size="size == 'sm'"
    v-bind:filter-number="filterNumber"
    v-on:input="setInputValue"
  />
</template>
<script>
export default {
  name: "TableInput",
  props: {
    value: {
      default: () => null,
    },
    row: {
      type: Object,
      default: () => {},
    },
    size: {
      default: "",
    },
    filterNumber: {
      default: false,
    },
  },
  computed: {
    componentId() {
      return (
        "wetable-content-row-cell-input-" + helper.getRandomInteger(1, 999999)
      );
    },
  },
  methods: {
    // setInputValue(value) {
    //   if (this.$refs.input) {
    //     this.$refs.input.value = value;
    //   }
    // },
    setInputValue(e) {
      const value = e || this.value;
      this.$emit("input", value);
    },
  },
  mounted() {
    this.setInputValue();
  },
};
</script>
